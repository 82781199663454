import "whatwg-fetch";

import type FetchConfig from "../models/FetchConfig.js";
import FetchExecutor from "./FetchExecutor.js";

/**
 * FetchExecutor when running on the client
 *
 * @author @jchaiken
 */
class ClientFetchExecutor extends FetchExecutor {
  protected makeRequestInit(config: FetchConfig): RequestInit {
    return {
      method: config.method,
      headers: this.getHeaders(config.headers),
      body: config.body ? JSON.stringify(config.body) : undefined,
      signal: config.signal,
      credentials: "include",
    };
  }

  protected getHost(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is exposed in root.tsx
    const windowObj = window as any;
    const isDev = windowObj.ENV.ENV === "development" || windowObj.ENV.ENV === "test";

    const protocol = isDev ? "http" : "https";
    // Only include the port if we're running locally or in test mode. In prod, this goes through DNS
    const port = isDev ? windowObj.ENV.TURBINE_HTTP_PORT : undefined;
    const host = windowObj.ENV.TURBINE_HOST;

    return `${protocol}://${[host, port].filter(Boolean).join(":")}`;
  }
}

export default ClientFetchExecutor;
