import ExecutionEnvironment from "exenv";
import { serverOnly$ } from "vite-env-only/macros";

import ClientFetchExecutor from "./executors/ClientFetchExecutor.js";
import type HttpExecutor from "./executors/HttpExecutor.js";
import ServerFetchExecutor from "./executors/ServerFetchExecutor.js";
import type ActionConfig from "./models/ActionConfig.js";
import type ApiConfig from "./models/ApiConfig.js";
import type GetConfig from "./models/GetConfig.js";

/**
 * Base class for all API clients.
 *
 * @author @jchaiken1
 */
abstract class Api {
  protected executor: HttpExecutor;
  protected path?: string;

  constructor(protected config?: ApiConfig) {
    if (ExecutionEnvironment.canUseDOM) {
      this.executor = new ClientFetchExecutor(this.config);
    } else {
      const serverExecutor = serverOnly$(() => new ServerFetchExecutor(this.config))?.();
      if (!serverExecutor) {
        throw new Error("Could not create server executor");
      }

      this.executor = serverExecutor;
    }
  }

  post<T>(config: ActionConfig): Promise<T> {
    return this.executor.post(config);
  }

  put<T>(config: ActionConfig): Promise<T> {
    return this.executor.put(config);
  }

  patch<T>(config: ActionConfig): Promise<T> {
    return this.executor.patch(config);
  }

  get<T>(config: GetConfig): Promise<T> {
    return this.executor.get(config);
  }

  delete<T>(config: ActionConfig): Promise<T> {
    return this.executor.delete(config);
  }

  protected v1Path(path: string) {
    return `/api/v1${path}`;
  }

  protected workspacePath(workspaceSlug: string, path: string) {
    return this.v1Path(`/w/${workspaceSlug}${path}`);
  }

  protected adminPath(path: string) {
    return this.v1Path(`/admin${path}`);
  }

  protected publicPath(path: string) {
    return this.v1Path(`/public${path}`);
  }

  protected internalPath(path: string) {
    return this.v1Path(`/internal${path}`);
  }

  protected static configForLoaderRequest(request: Request): ApiConfig {
    return {
      cookies: request.headers.get("cookie") ?? undefined,
      authorization: request.headers.get("authorization") ?? undefined,
      // The error boundary will handle the thrown error
      throwResponseError: true,
    };
  }

  protected static configForActionRequest(request: Request): ApiConfig {
    return {
      cookies: request.headers.get("cookie") ?? undefined,
      authorization: request.headers.get("authorization") ?? undefined,
      // The action handler will properly handle the error and throw a response with form validation handled
      throwResponseError: false,
    };
  }
}

export default Api;
