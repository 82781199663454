import * as Sentry from "@sentry/remix";
import qs from "qs";

import type ActionConfig from "../models/ActionConfig.js";
import type ApiConfig from "../models/ApiConfig.js";
import type FetchConfig from "../models/FetchConfig.js";
import type GetConfig from "../models/GetConfig.js";

export const MAX_NETWORK_RETRIES = 2;

function isObjectFilled(obj: object) {
  return Object.keys(obj).length > 0;
}

export function formatQueryParams(params: Record<string, unknown>): string {
  if (!params || typeof params !== "object" || !isObjectFilled(params)) {
    return "";
  }

  const queryStr = qs.stringify(params, { indices: false });
  return queryStr ? `?${queryStr}` : "";
}

/**
 * Base class for all HTTP executors.
 *
 * @author @jchaiken
 */
abstract class HttpExecutor {
  constructor(protected config?: ApiConfig) {}

  get<T>({ path, query = {}, headers = {}, signal }: GetConfig): Promise<T> {
    const formattedParams = formatQueryParams(query);
    const url = this.getHost() + path;

    return this.fetch({ url: url + formattedParams, method: "GET", headers, signal });
  }

  post<T>({ path, headers = {}, data, signal }: ActionConfig): Promise<T> {
    const url = this.getHost() + path;

    return this.fetch({ url, method: "POST", headers, body: data, signal });
  }

  put<T>({ path, headers = {}, data, signal }: ActionConfig): Promise<T> {
    const url = this.getHost() + path;

    return this.fetch({ url, method: "PUT", headers, body: data, signal });
  }

  patch<T>({ path, headers = {}, data, signal }: ActionConfig): Promise<T> {
    const url = this.getHost() + path;

    return this.fetch({ url, method: "PATCH", headers, body: data, signal });
  }

  delete<T>({ path, headers = {}, data, signal }: ActionConfig): Promise<T> {
    const url = this.getHost() + path;

    return this.fetch({ url, method: "DELETE", headers, body: data, signal });
  }

  abstract fetch<T>(config: FetchConfig): Promise<T>;

  protected abstract makeRequestInit(config: FetchConfig): RequestInit;

  protected abstract getHost(): string;

  protected getHeaders(requestHeaders?: HeadersInit): Headers {
    const headers = new Headers((requestHeaders as Record<string, string>) || {});

    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");

    if (typeof window === "undefined") {
      if (this.config?.cookies) {
        headers.set("cookie", this.config.cookies);
      }
      if (this.config?.authorization) {
        headers.set("authorization", this.config.authorization);
      }
    }

    for (const key of [...headers.keys()]) {
      if (headers.get(key) == "null") {
        headers.delete(key);
      }
    }

    return headers;
  }

  protected captureFetchError({
    err,
    url,
    config,
    requestTimeMs,
    retries,
  }: {
    err: Error & { cause?: unknown };
    url: string;
    config: Record<string, any>;
    requestTimeMs: number;
    retries: number;
  }) {
    Sentry.captureException(err, (scope) => {
      scope.setExtras({
        url,
        cause: err.cause,
        original: err,
        requestTimeMs,
        retries,
        retriesRemaining: MAX_NETWORK_RETRIES - retries,
        config: {
          ...config,
          headers:
            config.headers instanceof Headers
              ? Object.fromEntries(config.headers.entries())
              : config.headers,
        },
        executor: this.constructor.name,
      });

      return scope;
    });
  }
}

export default HttpExecutor;
